import './AcademyContainer.scss'

import { useTranslation } from 'react-i18next'
import AcademyContainerStats from './AcademyContainerStats/AcademyContainerStats'
import AcademyContainerItems from './AcademyContainerItems/AcademyContainerItems'

export default function AcademyContainer() {

    const { t } = useTranslation()

    return (
        <div className='academy-container w-100 h-100 d-flex flex-column overflow-auto'>

            <div className='academy-container-title d-flex w-100'>{t("academyContainer.title")}</div>

            <div className='academy-container-cap d-flex w-100 justify-content-center'>
                <AcademyContainerStats />
            </div>

            <div className='academy-container-items d-flex w-100 h-100 justify-content-center'>
                <AcademyContainerItems />
            </div>
            
        </div>
    )

}