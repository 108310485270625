//@ts-nocheck
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function AcademyContainerItemsLevel0() {

    const { t } = useTranslation()

    return (
        <>
            <div className='d-flex flex-column w-100'>
                <div className='academy-container-items-column d-flex flex-column w-100'>

                    <div className='academy-container-item'>

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/greed_1.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.greed")} 1
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-item-to d-flex justify-content-center w-100'>

                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                <path d="M20 6.27089C20.0002 6.36878 19.9787 6.46549 19.9373 6.55416C19.8958 6.64283 19.8354 6.72128 19.7601 6.78392L10.4323 14.516C10.3129 14.6149 10.1627 14.6689 10.0076 14.6689C9.85252 14.6689 9.70231 14.6149 9.58284 14.516L0.255041 6.78392C0.185037 6.72901 0.126741 6.66063 0.083593 6.58282C0.0404446 6.50501 0.0133177 6.41934 0.00381283 6.33088C-0.00569205 6.24241 0.0026176 6.15294 0.0282515 6.06774C0.0538855 5.98254 0.0963244 5.90334 0.153064 5.83481C0.209804 5.76627 0.279696 5.7098 0.358616 5.66872C0.437535 5.62763 0.523884 5.60277 0.612568 5.5956C0.701252 5.58843 0.790473 5.59909 0.874969 5.62696C0.959464 5.65483 1.03752 5.69934 1.10454 5.75786L10.0059 13.1368L18.9073 5.75786C19.0047 5.67677 19.1231 5.62512 19.2488 5.60897C19.3745 5.59282 19.5022 5.61285 19.6169 5.6667C19.7316 5.72056 19.8286 5.806 19.8964 5.91302C19.9643 6.02003 20.0002 6.14418 20 6.27089Z" fill="#B0B0B0" />
                                <path d="M20 0.677387C20.0002 0.77527 19.9787 0.871985 19.9373 0.960655C19.8958 1.04932 19.8354 1.12777 19.7601 1.19042L10.4323 8.9225C10.3129 9.02135 10.1627 9.07544 10.0076 9.07544C9.85252 9.07544 9.70231 9.02135 9.58284 8.9225L0.255041 1.19042C0.185037 1.1355 0.126741 1.06712 0.083593 0.98931C0.0404446 0.911501 0.0133177 0.825837 0.00381283 0.737373C-0.00569205 0.64891 0.0026176 0.559437 0.0282515 0.474236C0.0538855 0.389036 0.0963244 0.309834 0.153064 0.241301C0.209804 0.172768 0.279696 0.116293 0.358616 0.0752096C0.437535 0.0341253 0.523884 0.00926304 0.612568 0.00209141C0.701252 -0.00508022 0.790473 0.00558376 0.874969 0.033453C0.959464 0.0613222 1.03752 0.105832 1.10454 0.164358L10.0059 7.54665L18.9073 0.164358C19.0047 0.0832605 19.1231 0.0316095 19.2488 0.0154629C19.3745 -0.000682831 19.5022 0.0193453 19.6169 0.0731983C19.7316 0.127052 19.8286 0.212498 19.8964 0.319512C19.9643 0.426527 20.0002 0.550673 20 0.677387Z" fill="#B0B0B0" />
                            </g>
                        </svg>

                    </div>

                    <div className='academy-container-item'>

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/greed_2.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                    {t("academyContainerScienceModal.names.greed")} 2
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-item-to d-flex justify-content-center w-100'>

                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                <path d="M20 6.27089C20.0002 6.36878 19.9787 6.46549 19.9373 6.55416C19.8958 6.64283 19.8354 6.72128 19.7601 6.78392L10.4323 14.516C10.3129 14.6149 10.1627 14.6689 10.0076 14.6689C9.85252 14.6689 9.70231 14.6149 9.58284 14.516L0.255041 6.78392C0.185037 6.72901 0.126741 6.66063 0.083593 6.58282C0.0404446 6.50501 0.0133177 6.41934 0.00381283 6.33088C-0.00569205 6.24241 0.0026176 6.15294 0.0282515 6.06774C0.0538855 5.98254 0.0963244 5.90334 0.153064 5.83481C0.209804 5.76627 0.279696 5.7098 0.358616 5.66872C0.437535 5.62763 0.523884 5.60277 0.612568 5.5956C0.701252 5.58843 0.790473 5.59909 0.874969 5.62696C0.959464 5.65483 1.03752 5.69934 1.10454 5.75786L10.0059 13.1368L18.9073 5.75786C19.0047 5.67677 19.1231 5.62512 19.2488 5.60897C19.3745 5.59282 19.5022 5.61285 19.6169 5.6667C19.7316 5.72056 19.8286 5.806 19.8964 5.91302C19.9643 6.02003 20.0002 6.14418 20 6.27089Z" fill="#B0B0B0" />
                                <path d="M20 0.677387C20.0002 0.77527 19.9787 0.871985 19.9373 0.960655C19.8958 1.04932 19.8354 1.12777 19.7601 1.19042L10.4323 8.9225C10.3129 9.02135 10.1627 9.07544 10.0076 9.07544C9.85252 9.07544 9.70231 9.02135 9.58284 8.9225L0.255041 1.19042C0.185037 1.1355 0.126741 1.06712 0.083593 0.98931C0.0404446 0.911501 0.0133177 0.825837 0.00381283 0.737373C-0.00569205 0.64891 0.0026176 0.559437 0.0282515 0.474236C0.0538855 0.389036 0.0963244 0.309834 0.153064 0.241301C0.209804 0.172768 0.279696 0.116293 0.358616 0.0752096C0.437535 0.0341253 0.523884 0.00926304 0.612568 0.00209141C0.701252 -0.00508022 0.790473 0.00558376 0.874969 0.033453C0.959464 0.0613222 1.03752 0.105832 1.10454 0.164358L10.0059 7.54665L18.9073 0.164358C19.0047 0.0832605 19.1231 0.0316095 19.2488 0.0154629C19.3745 -0.000682831 19.5022 0.0193453 19.6169 0.0731983C19.7316 0.127052 19.8286 0.212498 19.8964 0.319512C19.9643 0.426527 20.0002 0.550673 20 0.677387Z" fill="#B0B0B0" />
                            </g>
                        </svg>

                    </div>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/greed_3.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                    {t("academyContainerScienceModal.names.greed")} 3
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className='d-flex flex-column w-100'>
                <div className='academy-container-items-column d-flex flex-column w-100'>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/demographics_1.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.demographics")} 1
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-item-to d-flex justify-content-center w-100'>

                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                <path d="M20 6.27089C20.0002 6.36878 19.9787 6.46549 19.9373 6.55416C19.8958 6.64283 19.8354 6.72128 19.7601 6.78392L10.4323 14.516C10.3129 14.6149 10.1627 14.6689 10.0076 14.6689C9.85252 14.6689 9.70231 14.6149 9.58284 14.516L0.255041 6.78392C0.185037 6.72901 0.126741 6.66063 0.083593 6.58282C0.0404446 6.50501 0.0133177 6.41934 0.00381283 6.33088C-0.00569205 6.24241 0.0026176 6.15294 0.0282515 6.06774C0.0538855 5.98254 0.0963244 5.90334 0.153064 5.83481C0.209804 5.76627 0.279696 5.7098 0.358616 5.66872C0.437535 5.62763 0.523884 5.60277 0.612568 5.5956C0.701252 5.58843 0.790473 5.59909 0.874969 5.62696C0.959464 5.65483 1.03752 5.69934 1.10454 5.75786L10.0059 13.1368L18.9073 5.75786C19.0047 5.67677 19.1231 5.62512 19.2488 5.60897C19.3745 5.59282 19.5022 5.61285 19.6169 5.6667C19.7316 5.72056 19.8286 5.806 19.8964 5.91302C19.9643 6.02003 20.0002 6.14418 20 6.27089Z" fill="#B0B0B0" />
                                <path d="M20 0.677387C20.0002 0.77527 19.9787 0.871985 19.9373 0.960655C19.8958 1.04932 19.8354 1.12777 19.7601 1.19042L10.4323 8.9225C10.3129 9.02135 10.1627 9.07544 10.0076 9.07544C9.85252 9.07544 9.70231 9.02135 9.58284 8.9225L0.255041 1.19042C0.185037 1.1355 0.126741 1.06712 0.083593 0.98931C0.0404446 0.911501 0.0133177 0.825837 0.00381283 0.737373C-0.00569205 0.64891 0.0026176 0.559437 0.0282515 0.474236C0.0538855 0.389036 0.0963244 0.309834 0.153064 0.241301C0.209804 0.172768 0.279696 0.116293 0.358616 0.0752096C0.437535 0.0341253 0.523884 0.00926304 0.612568 0.00209141C0.701252 -0.00508022 0.790473 0.00558376 0.874969 0.033453C0.959464 0.0613222 1.03752 0.105832 1.10454 0.164358L10.0059 7.54665L18.9073 0.164358C19.0047 0.0832605 19.1231 0.0316095 19.2488 0.0154629C19.3745 -0.000682831 19.5022 0.0193453 19.6169 0.0731983C19.7316 0.127052 19.8286 0.212498 19.8964 0.319512C19.9643 0.426527 20.0002 0.550673 20 0.677387Z" fill="#B0B0B0" />
                            </g>
                        </svg>

                    </div>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '63px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/demographics_2.png`} height='63px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.demographics")} 2
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='academy-container-items-column d-flex flex-column w-100'>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/qualification_1.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.qualification")}1
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className='d-flex flex-column w-100'>
                <div className='academy-container-items-column d-flex flex-column w-100'>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/leadership_1.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.leadership")} 1
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-item-to d-flex justify-content-center w-100'>

                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                <path d="M20 6.27089C20.0002 6.36878 19.9787 6.46549 19.9373 6.55416C19.8958 6.64283 19.8354 6.72128 19.7601 6.78392L10.4323 14.516C10.3129 14.6149 10.1627 14.6689 10.0076 14.6689C9.85252 14.6689 9.70231 14.6149 9.58284 14.516L0.255041 6.78392C0.185037 6.72901 0.126741 6.66063 0.083593 6.58282C0.0404446 6.50501 0.0133177 6.41934 0.00381283 6.33088C-0.00569205 6.24241 0.0026176 6.15294 0.0282515 6.06774C0.0538855 5.98254 0.0963244 5.90334 0.153064 5.83481C0.209804 5.76627 0.279696 5.7098 0.358616 5.66872C0.437535 5.62763 0.523884 5.60277 0.612568 5.5956C0.701252 5.58843 0.790473 5.59909 0.874969 5.62696C0.959464 5.65483 1.03752 5.69934 1.10454 5.75786L10.0059 13.1368L18.9073 5.75786C19.0047 5.67677 19.1231 5.62512 19.2488 5.60897C19.3745 5.59282 19.5022 5.61285 19.6169 5.6667C19.7316 5.72056 19.8286 5.806 19.8964 5.91302C19.9643 6.02003 20.0002 6.14418 20 6.27089Z" fill="#B0B0B0" />
                                <path d="M20 0.677387C20.0002 0.77527 19.9787 0.871985 19.9373 0.960655C19.8958 1.04932 19.8354 1.12777 19.7601 1.19042L10.4323 8.9225C10.3129 9.02135 10.1627 9.07544 10.0076 9.07544C9.85252 9.07544 9.70231 9.02135 9.58284 8.9225L0.255041 1.19042C0.185037 1.1355 0.126741 1.06712 0.083593 0.98931C0.0404446 0.911501 0.0133177 0.825837 0.00381283 0.737373C-0.00569205 0.64891 0.0026176 0.559437 0.0282515 0.474236C0.0538855 0.389036 0.0963244 0.309834 0.153064 0.241301C0.209804 0.172768 0.279696 0.116293 0.358616 0.0752096C0.437535 0.0341253 0.523884 0.00926304 0.612568 0.00209141C0.701252 -0.00508022 0.790473 0.00558376 0.874969 0.033453C0.959464 0.0613222 1.03752 0.105832 1.10454 0.164358L10.0059 7.54665L18.9073 0.164358C19.0047 0.0832605 19.1231 0.0316095 19.2488 0.0154629C19.3745 -0.000682831 19.5022 0.0193453 19.6169 0.0731983C19.7316 0.127052 19.8286 0.212498 19.8964 0.319512C19.9643 0.426527 20.0002 0.550673 20 0.677387Z" fill="#B0B0B0" />
                            </g>
                        </svg>

                    </div>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/leadership_2.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                    {t("academyContainerScienceModal.names.leadership")} 2
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-item-to d-flex justify-content-center w-100'>

                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                <path d="M20 6.27089C20.0002 6.36878 19.9787 6.46549 19.9373 6.55416C19.8958 6.64283 19.8354 6.72128 19.7601 6.78392L10.4323 14.516C10.3129 14.6149 10.1627 14.6689 10.0076 14.6689C9.85252 14.6689 9.70231 14.6149 9.58284 14.516L0.255041 6.78392C0.185037 6.72901 0.126741 6.66063 0.083593 6.58282C0.0404446 6.50501 0.0133177 6.41934 0.00381283 6.33088C-0.00569205 6.24241 0.0026176 6.15294 0.0282515 6.06774C0.0538855 5.98254 0.0963244 5.90334 0.153064 5.83481C0.209804 5.76627 0.279696 5.7098 0.358616 5.66872C0.437535 5.62763 0.523884 5.60277 0.612568 5.5956C0.701252 5.58843 0.790473 5.59909 0.874969 5.62696C0.959464 5.65483 1.03752 5.69934 1.10454 5.75786L10.0059 13.1368L18.9073 5.75786C19.0047 5.67677 19.1231 5.62512 19.2488 5.60897C19.3745 5.59282 19.5022 5.61285 19.6169 5.6667C19.7316 5.72056 19.8286 5.806 19.8964 5.91302C19.9643 6.02003 20.0002 6.14418 20 6.27089Z" fill="#B0B0B0" />
                                <path d="M20 0.677387C20.0002 0.77527 19.9787 0.871985 19.9373 0.960655C19.8958 1.04932 19.8354 1.12777 19.7601 1.19042L10.4323 8.9225C10.3129 9.02135 10.1627 9.07544 10.0076 9.07544C9.85252 9.07544 9.70231 9.02135 9.58284 8.9225L0.255041 1.19042C0.185037 1.1355 0.126741 1.06712 0.083593 0.98931C0.0404446 0.911501 0.0133177 0.825837 0.00381283 0.737373C-0.00569205 0.64891 0.0026176 0.559437 0.0282515 0.474236C0.0538855 0.389036 0.0963244 0.309834 0.153064 0.241301C0.209804 0.172768 0.279696 0.116293 0.358616 0.0752096C0.437535 0.0341253 0.523884 0.00926304 0.612568 0.00209141C0.701252 -0.00508022 0.790473 0.00558376 0.874969 0.033453C0.959464 0.0613222 1.03752 0.105832 1.10454 0.164358L10.0059 7.54665L18.9073 0.164358C19.0047 0.0832605 19.1231 0.0316095 19.2488 0.0154629C19.3745 -0.000682831 19.5022 0.0193453 19.6169 0.0731983C19.7316 0.127052 19.8286 0.212498 19.8964 0.319512C19.9643 0.426527 20.0002 0.550673 20 0.677387Z" fill="#B0B0B0" />
                            </g>
                        </svg>

                    </div>

                    <div className='academy-container-item'  >

                        <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                            <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                <img src={`img/academy/leadership_3.png`} height='60px' width='auto' />
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                        {t("academyContainerScienceModal.names.leadership")} 3
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}