//@ts-nocheck

import store from '../redux/store'

import { getStartParamRef } from '../utils/telegram'

import { getStats, upsertUser } from '../api/user'
import { getUserLoot } from '../api/userLoot'
import { getUserHeroes } from '../api/userHero'

import { setUserLootCountGold } from '../redux/userLootGoldSlice/userLootGoldSlice'
import { setUserLootCountCrystal } from '../redux/userLootCrystalSlice/userLootCrystalSlice'
import { setUserHeroesDamage, setUserHeroesDamageAcademy, setUserHeroesDamageDPC, setUserHeroesDamageDPS, setUserHeroesDamageIPS } from '../redux/userHeroesDamageSlice/userHeroesDamageSlice'
import { setUserEnergyCount, setUserEnergyCountStorageFirstTime } from '../redux/userEnergyCountSlice/userEnergyCountSlice'

import { setUserQuestCount } from '../redux/userQuestCountSlice/userQuestCountSlice'
import { getUserDailyRewards } from '../api/userDailyReward'
import { getUserQuestSocial } from '../api/userQuestSocial'
import { setUserApiError } from '../redux/Error/userApiError/userApiErrorSlice'
import BigNumber from "bignumber.js";
import { get } from '../api/userEnergy'
import { getByUserId } from '../api/UserNotification'
import { setUserNotifications } from '../redux/userNotificationsSlice/userNotificationsSlice'
import { setUserLootCountEnergyBottle } from '../redux/userLootEnergyBottleSlice/userLootEnergyBottleSlice'
import { setUserLootCountOldCoin } from '../redux/userLootOldCoinSlice/userLootOldCoinSlice'
import { setUserBan } from '../redux/userBanSlice/userBanSlice'
import { setIsFirstTime } from '../redux/userSlice/userSlice'
import axios from 'axios'
import { setUserHeroBonusEVENTOLDCOIN } from '../redux/userHeroBonusSlice/userHeroBonusSlice'

export const startInitData = async () => {
  try {
    const userFetch = await upsertUser(getStartParamRef())
    if (userFetch.status !== 200) {
      return
    }
    if (!userFetch.data.upsertUser) {
      return
    }

    if (userFetch.data.isBan) {
      store.dispatch(setUserBan(true))
      return
    }

    if (userFetch.data.isFirstTime) {
      store.dispatch(setIsFirstTime(true))
    }

    axios.defaults.headers.common['hashUuidv4'] = userFetch.data.hashUuidv4

    await initDataUserLootCount()
    await initDataUserHeroesDamage()
    await initDataUserEnergyCount()
    await initDataUserQuestCount()
    await initDataUserNotification()
  } catch {
    store.dispatch(setUserApiError(true))
  }
}

const initDataUserLootCount = async () => {
  const userLoot = await getUserLoot()
  for (const userLootEl of userLoot.data) {
    if (userLootEl.name === "Gold")
      store.dispatch(setUserLootCountGold(userLootEl.count))
    if (userLootEl.name === "Crystal")
      store.dispatch(setUserLootCountCrystal(userLootEl.count))
    if (userLootEl.name === "OldCoin")
      store.dispatch(setUserLootCountOldCoin(userLootEl.count))
    if (userLootEl.name === "EnergyBottle")
      store.dispatch(setUserLootCountEnergyBottle(userLootEl.count))
  }

  return true
}

export const initDataUserHeroesDamage = async () => {

  const userStats = await getStats()

  store.dispatch(setUserHeroesDamageDPC(userStats.heroDamage.DPC))
  store.dispatch(setUserHeroesDamageDPS(userStats.heroDamage.DPS))
  store.dispatch(setUserHeroesDamage(userStats.heroDamage.damage))  
  store.dispatch(setUserHeroesDamageIPS(userStats.heroDamage.IPS))
  store.dispatch(setUserHeroesDamageAcademy(userStats.heroDamage.academy))
  store.dispatch(setUserHeroBonusEVENTOLDCOIN(userStats.heroBonus.EVENTOLDCOIN))

  return true
}

const initDataUserEnergyCount = async () => {
  const data = await get()
  store.dispatch(setUserEnergyCount(data.count))

  return true
}

export const initDataUserQuestCount = async () => {
  let userQuestCount = 0

  const dataUserDailyRewards = await getUserDailyRewards()
  if (dataUserDailyRewards.data.timeAwait == 0)
    userQuestCount++

  const dataUserQuestSocial = await getUserQuestSocial()
  let noCompletedUserQuestSocial = 0
  for (const element of dataUserQuestSocial.questSocial) {
    if (!element.active) continue
    if (!element.completed)
      noCompletedUserQuestSocial++
  }

  store.dispatch(setUserQuestCount(userQuestCount + noCompletedUserQuestSocial))

  return true
}

export const initDataUserNotification = async () => {

  const userNotification = await getByUserId()

  store.dispatch(setUserNotifications(userNotification))

  return true
}