//@ts-nocheck
import './AcademyContainerItems.scss'

import { useTranslation } from 'react-i18next'
import { getAcademyItems } from '../../../api/clan'
import { useQuery } from 'react-query'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import AcademyContainerItemsLevel0 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel0'
import AcademyContainerItemsLevel1 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel1'
import AcademyContainerItemsLevel2 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel2'
import AcademyContainerItemsLevel3 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel3'
import AcademyContainerItemsLevel4 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel4'
import AcademyContainerItemsLevel5 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel5'
import AcademyContainerItemsLevel6 from './AcademyContainerItemsLevel/AcademyContainerItemsLevel6'

export default function AcademyContainerItems() {

    const { t } = useTranslation()
    const { data, isLoading, isSuccess } = useQuery(
        ['clanAcademyItems'],
        () => getAcademyItems(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='academy-container-items w-100 h-100 d-flex justify-content-between' style={{ gap: '8px' }}>
            {isSuccess &&
                <>
                    {data.level === 0 &&
                        <AcademyContainerItemsLevel0 />
                    }

                    {data.level === 1 &&
                        <AcademyContainerItemsLevel1 items={data.items} />
                    }

                    {data.level === 2 &&
                        <AcademyContainerItemsLevel2 items={data.items} />
                    }

                    {data.level === 3 &&
                        <AcademyContainerItemsLevel3 items={data.items} />
                    }

                    {data.level === 4 &&
                        <AcademyContainerItemsLevel4 items={data.items} />
                    }

                    {data.level === 5 &&
                        <AcademyContainerItemsLevel5 items={data.items} />
                    }

                    {data.level === 6 &&
                        <AcademyContainerItemsLevel6 items={data.items} />
                    }
                </>
            }
            {isLoading &&
                <DataFetchLoader width={80} height={80} />
            }
        </div>
    )
}