//@ts-nocheck

import React, { useState } from 'react'
import AcademyContainerScienceModal from '../../AcademyContainerScienceModal/AcademyContainerScienceModal'
import AcademyContainerItemsLevelArrow from './AcademyContainerItemsLevelArrow/AcademyContainerItemsLevelArrow'
import { useTranslation } from 'react-i18next'
import AcademyContainerItem from './AcademyContainerItem/AcademyContainerItem'

export default function AcademyContainerItemsLevel5({ items }) {

    const { t } = useTranslation()

    const [selectItem, setSelectItem] = useState({
        name: "",
        level: -1,
    })
    const academyContainerItemOnClick = (name, level) => {
        setSelectItem({
            name: items[name].name,
            level: level,
        })
        var myModal = new bootstrap.Modal(document.getElementById('scienceModal'))
        myModal.show()
    }

    return (
        <>
            <div className='d-flex flex-column w-100'>
                <div className='w-100 d-flex' style={{ gap: '8px' }}>
                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"greed"} level={9} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"demographics"} level={4} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"leadership"} level={9} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>
                </div>

                <div className='w-100 d-flex' style={{ gap: '8px' }}>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"workshop"} level={1} onClick={academyContainerItemOnClick} />
                            <AcademyContainerItemsLevelArrow />
                            <AcademyContainerItem items={items} name={"workshop"} level={2} onClick={academyContainerItemOnClick} />
                            <AcademyContainerItemsLevelArrow />
                            <AcademyContainerItem items={items} name={"workshop"} level={3} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"attack"} level={9} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"protection"} level={9} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>
                </div>

                <div className='w-100 d-flex' style={{ gap: '8px' }}>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"alchemy"} level={1} onClick={academyContainerItemOnClick} />
                            <AcademyContainerItemsLevelArrow />
                            <AcademyContainerItem items={items} name={"alchemy"} level={2} onClick={academyContainerItemOnClick} />
                            <AcademyContainerItemsLevelArrow />
                            <AcademyContainerItem items={items} name={"alchemy"} level={3} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                        <div className='academy-container-items-column d-flex flex-column w-100'>
                            <AcademyContainerItem items={items} name={"qualification"} level={9} onClick={academyContainerItemOnClick} />
                        </div>
                    </div>

                    <div className='d-flex flex-column w-100'>
                    </div>
                </div>
                
            </div>

            <AcademyContainerScienceModal item={selectItem} />
        </>
    )
}