//@ts-nocheck
import AcademyContainer from '../../components/AcademyContainer/AcademyContainer'
import './Academy.scss'

import { useTranslation } from 'react-i18next'

export default function Academy() {

    const { t } = useTranslation()

    return (
        <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
            <AcademyContainer />
        </main>
    )
}