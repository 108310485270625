//@ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function AcademyContainerItem({ items, name, level, onClick }) {

    const { t } = useTranslation()

    return (
        <div onClick={() => onClick(name, level)} className='academy-container-item'>

            <div className='academy-container-items-image d-flex justify-content-center w-100 position-relative'>
                <div style={{ width: '60px', filter: `grayscale(${items[name].level + 1 >= level ? '0' : '100'}%)` }} className='d-flex justify-content-center'>
                    <img src={`/img/academy/${name}_${level}.png`} height='60px' width='auto' />
                </div>
            </div>

            <div className='science_bar d-flex justify-content-center w-100'>
                <div className='container-science-bar d-flex align-items-center position-relative'>
                    <div id='science-bar' className={`${items[name].level + 1 >= level ? 'science-bar' : 'science-bar-notactive'} w-100 d-flex justify-content-start align-items-center`}>
                        {items[name].level >= level &&
                            <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start w-100'></div>
                        }
                        {items[name].level < level &&
                            <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start' style={{ width: items[name].level + 1 === level ? `${Math.round((items[name].current / items[name].levels[`level${level}`].price) * 100)}%` : '0%' }}></div>
                        }
                        <div className='science-text'>
                            {t(`academyContainerScienceModal.names.${name}`)} {level}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}