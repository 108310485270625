//@ts-nocheck
import { useEffect, useState } from 'react';
import './AcademyContainerScienceModal.scss'

import { useTranslation } from "react-i18next";
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAcademyItem, upgradeAcademyItem } from '../../../api/clan';
import ToastError from '../../Toasts/ToastError/ToastError';
import { UseAdsgram } from '../../UseAdsgram/UseAdsgram';

export default function AcademyContainerScienceModal({ item }) {

    const { t } = useTranslation()

    const academyDamage = useSelector(state => state.userHeroesDamage.academy)

    const [isLoadingEnd, setIsLoadingEnd] = useState(false)
    const { data, isLoading, isSuccess, refetch, isFetching } = useQuery(
        ['clanAcademyItem'],
        () => getAcademyItem(item.name, item.level),
        {
            keepPreviousData: true,
            onSuccess() {
                if(item.level === -1) return
                
                setIsLoadingEnd(true)
            }
        }
    )
    useEffect(() => {
        var myModalEl = document.getElementById('scienceModal')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            refetch()
        })
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            setIsLoadingEnd(false)
        })
    }, [])

    const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)

    const queryClient = useQueryClient()
    const upgradeAcademyItemMutation = useMutation((obj: Object) => upgradeAcademyItem(obj), {
        onSuccess: (data) => {
            if (!data.ok) {
                if(data.errorCode === "ImprovingIsNotAvailableLevel") {
                    ToastError(t(`academyContainer.errors.${data.errorCode}`), true)  
                    queryClient.invalidateQueries(['userLoot']).then(() => {
                        queryClient.invalidateQueries(['clanAcademyInfo']).then(() => {
                            queryClient.invalidateQueries(['clanAcademyItems']).then(() => {
                                queryClient.invalidateQueries(['clanAcademyItem']).then(() => {
                                    setUpgradeIsLoading(false)
                                })
                            })
                        })
                    })  
                    return
                }
                else {
                    ToastError(t(`academyContainer.errors.${data.errorCode}`), true)
                    setUpgradeIsLoading(false)
                    return
                }
            }
            queryClient.invalidateQueries(['userLoot']).then(() => {
                queryClient.invalidateQueries(['clanAcademyInfo']).then(() => {
                    queryClient.invalidateQueries(['clanAcademyItems']).then(() => {
                        queryClient.invalidateQueries(['clanAcademyItem']).then(() => {
                            setUpgradeIsLoading(false)
                        })
                    })
                })
            })
        },
        onError: () => {
            ToastError(t("toast.toastError"), true)
        }
    })
    const [upgradeIsLoading, setUpgradeIsLoading] = useState(false)
    const upgradeAcademyItemOnClick = (type: string) => {
        if (upgradeIsLoading) return

        if (type === "bottles") {
            if (countUserLootEnergyBottle - data.info.bottles < 0) {
                console.log(123);
                
                const shopHeroPriceElem = document.getElementById("academyItemBottlesPrice")
                shopHeroPriceElem.classList.toggle("animate-drag");
                setTimeout(() => {
                    shopHeroPriceElem.classList.remove("animate-drag");
                }, 300);

                return
            }
        }

        setUpgradeIsLoading(true)

        upgradeAcademyItemMutation.mutate({ type: type, name: item.name, level: item.level })
    }

    const showAd = UseAdsgram({
        blockId: '7787',
        onClick: () => {
            setUpgradeIsLoading(true)
        },
        onReward: () => {            
            upgradeAcademyItemMutation.mutate({ type: "ad", name: item.name, level: item.level })
        },
        onError: (error) => console.error('Error showing ad:', error),
    })

    return (
        <>
            <div className="modal fade" id={"scienceModal"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="academy-science-modal-container modal-content w-100 flex-column">
                        {isLoadingEnd &&
                            <>
                                {(isSuccess && !isFetching) &&
                                    <>
                                        <div className='academy-science-modal-desc d-flex justify-content-between' style={{ filter: `grayscale(${(data.info.status === 'active' || data.info.complete) ? '0%' : '100%'})` }}>
                                            <div className='d-flex position-relative'>
                                                <div style={{ width: '100px' }} className='d-flex justify-content-center'>
                                                    <img src={`/img/academy/${data.info.name}_${data.info.level}.png`} height='100px' width='auto' />
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column w-100'>
                                                <div className='academy-science-modal-desc-name'>{t(`academyContainerScienceModal.names.${data.info.name}`)}</div>
                                                <div className='academy-science-modal-desc-level'>{t(`academyContainerScienceModal.level`, { level: data.info.level })}</div>
                                                <div className='academy-science-modal-desc-details'>{t(`academyContainerScienceModal.bonuses.${data.info.name}`, { bonus: data.info.bonus })}</div>
                                                <div>
                                                    <div className='academy-science-modal-progress-bar justify-content-center w-100'>
                                                        <div className='progress_bar d-flex justify-content-center w-100'>
                                                            <div className='container-progress-bar d-flex align-items-center position-relative'>
                                                                <div id='progress-bar' className='progress-bar w-100 d-flex justify-content-start position-relative'>
                                                                    {data.info.complete &&
                                                                        <div id='progress' className='progress-bar-fill h-100 d-flex align-items-center justify-content-start w-100'></div>
                                                                    }
                                                                    {!data.info.complete &&
                                                                        <div id='progress' className='progress-bar-fill h-100 d-flex align-items-center justify-content-start' style={{ width: data.info.currentLevel + 1 === data.info.level ? `${Math.round((data.info.current / data.info.price) * 100)}%` : '0%' }}></div>
                                                                    }
                                                                    <div className='progress-text position-absolute top-50 start-50 translate-middle'>
                                                                        {data.info.complete &&
                                                                            <>
                                                                                {data.info.price} / {data.info.price}
                                                                            </>
                                                                        }
                                                                        {!data.info.complete &&
                                                                            <>
                                                                                {data.info.currentLevel + 1 === data.info.level &&
                                                                                    <>
                                                                                        {data.info.current} / {data.info.price}
                                                                                    </>
                                                                                }
                                                                                {data.info.currentLevel + 1 !== data.info.level &&
                                                                                    <>
                                                                                        {0} / {data.info.price}
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {data.info.status === 'active' &&
                                            <div className='academy-science-modal-buttons d-flex justify-content-center align-items-center'>
                                                <div className='d-flex flex-column'>
                                                    <div onClick={() => upgradeAcademyItemOnClick("bottles")} className='d-flex justify-content-center position-relative'>
                                                        <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M59.3332 14.8968L51.04 28.7912H13.4152L3.89502 14.8968L13.4152 1H51.04L59.3332 14.8968Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                                            <path d="M56.4382 15.1055L48.145 29H10.5202L1 15.1055L10.5202 1.20874H48.145L56.4382 15.1055Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                                        </svg>
                                                        <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                                            {!upgradeIsLoading &&
                                                                <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                                                                    <img src='/img/loot/energy-bottle.png' height={'auto'} width={16} style={{ marginRight: '3px' }} />
                                                                    <span id='academyItemBottlesPrice' style={{ color: countUserLootEnergyBottle - data.info.bottles < 0 ? "rgb(177, 61, 88)" : 'white' }}>{data.info.bottles}</span>
                                                                </div>
                                                            }
                                                            {upgradeIsLoading &&
                                                                <DataFetchLoader width={13} height={13} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='d-flex w-100 position-relative'>
                                                        <div className='d-flex academy-science-modal-buttons-count position-absolute top-100 start-50 translate-middle'>{Number(academyDamage) * 2}</div>
                                                    </div>
                                                </div>
                                                <div className='d-flex' style={{ marginLeft: '12px', marginRight: '12px' }}>
                                                    <div onClick={() => upgradeAcademyItemOnClick("standard")} className='d-flex justify-content-center position-relative'>
                                                        <svg width="110" height="38" viewBox="0 0 110 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M108.948 18.8673L93.7429 36.7316H24.7623L7.30811 18.8673L24.7623 1H93.7429L108.948 18.8673Z" fill="#171027" fill-opacity="0.3" stroke="#B0B0B0" stroke-miterlimit="10" />
                                                            <path d="M103.639 19.1356L88.4348 36.9999H19.4542L2 19.1356L19.4542 1.26831H88.4348L103.639 19.1356Z" stroke="#B0B0B0" stroke-width="2" stroke-miterlimit="10" />
                                                        </svg>
                                                        <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                                            {!upgradeIsLoading &&
                                                                <div className='d-flex align-items-center justify-content-center m-0 p-0' style={{ fontSize: '20px' }}>
                                                                    {Number(academyDamage)}
                                                                </div>
                                                            }
                                                            {upgradeIsLoading &&
                                                                <DataFetchLoader width={20} height={20} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <div onClick={showAd} className='d-flex justify-content-center position-relative'>
                                                        <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M59.3332 14.8968L51.04 28.7912H13.4152L3.89502 14.8968L13.4152 1H51.04L59.3332 14.8968Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                                            <path d="M56.4382 15.1055L48.145 29H10.5202L1 15.1055L10.5202 1.20874H48.145L56.4382 15.1055Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                                        </svg>
                                                        <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                                            {!upgradeIsLoading &&
                                                                <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                                                                    {data.info.ad} / 10
                                                                </div>
                                                            }
                                                            {upgradeIsLoading &&
                                                                <DataFetchLoader width={13} height={13} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='d-flex w-100 position-relative'>
                                                        <div className='d-flex academy-science-modal-buttons-count position-absolute top-100 start-50 translate-middle'>{Number(academyDamage) * 1.2}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {(isLoading && !isFetching) &&
                                    <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                                }
                                {isFetching &&
                                    <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                                }
                            </>
                        }
                        {!isLoadingEnd &&
                            <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
                        }
                    </div>
                </div>

            </div >
        </>
    )
}