//@ts-nocheck

import './AcademyContainerStats.scss'

import { useTranslation } from 'react-i18next'
import AcademyContainerBookModal from '../AcademyContainerBookModal/AcademyContainerBookModal'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getAcademyInfo, academyRestore } from '../../../api/clan'
import { useState } from 'react'
import ToastError from '../../Toasts/ToastError/ToastError'
import AcademyContainerBookUpgradeModal from '../AcademyContainerBookUpgradeModal/AcademyContainerBookUpgradeModal'
import { useSelector } from 'react-redux'
import { numberFormat } from '../../../utils/NumberFormat'

export default function AcademyContainerStats() {

    const { t } = useTranslation()
    const { data, isLoading, isSuccess } = useQuery(
        ['clanAcademyInfo'],
        () => getAcademyInfo(),
        {
            keepPreviousData: true,
        }
    )

    const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)

    const [restoreIsLoading, setRestoreIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const restoreMutation = useMutation(() => academyRestore(), {
        onSuccess: (data) => {
            if(!data.ok) {
                ToastError(t(`academyContainer.errors.${data.errorCode}`), true) 
            }
            queryClient.invalidateQueries(['userLoot']).then(() => {
                queryClient.invalidateQueries(['clanAcademyInfo']).then(() => {
                    setRestoreIsLoading(false)
                })
            })
        },
        onError: () => {
            ToastError(t("toast.toastError"), true)
        }
    })
    const restoreOnClick = () => {
        if (restoreIsLoading) return

        if (countUserLootEnergyBottle - 50 < 0) {
            const shopHeroPriceElem = document.getElementById("restorePrice")
            shopHeroPriceElem.classList.toggle("animate-drag");
            setTimeout(() => {
                shopHeroPriceElem.classList.remove("animate-drag");
            }, 300);

            return
        }

        setRestoreIsLoading(true)

        restoreMutation.mutate()
    }

    return (
        <div className={'academy-container-stats h-100 d-flex justify-content-center'}>
            {isSuccess &&
                <>
                    <div className='academy-container-stats-current-book d-flex flex-column'>

                        <div className='academy-container-stats-book d-flex justify-content-center w-100'>
                            <div className='academy-container-stats-book-image position-relative'>
                                <div className='academy-container-stats-book-info position-absolute' data-bs-toggle="modal" data-bs-target={"#bookModal"}>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse opacity="0.9" cx="8.2227" cy="8.20513" rx="8.2227" ry="8.20513" fill="#B28E4C" />
                                        <path
                                            d="M7.76705 12V6.54545H8.60511V12H7.76705ZM8.19318 5.63636C8.02983 5.63636 7.88897 5.58073 7.7706 5.46946C7.65459 5.35819 7.59659 5.22443 7.59659 5.06818C7.59659 4.91193 7.65459 4.77817 7.7706 4.6669C7.88897 4.55563 8.02983 4.5 8.19318 4.5C8.35653 4.5 8.49621 4.55563 8.61222 4.6669C8.73059 4.77817 8.78977 4.91193 8.78977 5.06818C8.78977 5.22443 8.73059 5.35819 8.61222 5.46946C8.49621 5.58073 8.35653 5.63636 8.19318 5.63636Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <div style={{ width: '60px' }} className='d-flex justify-content-center' data-bs-toggle={`${data.book.level + 1 !== 7 ? 'modal' : ''}`} data-bs-target={"#bookUpgradeModal"}>
                                    <img src={`/img/academy/book_level_${data.book.level + 1}.png`} height='60px' width='auto' />
                                </div>
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start' style={{width: `${Math.round((data.book.current / data.book.price) * 100)}%`}}></div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-stats-energy'>

                        <div className='w-100 d-flex justify-content-between'>
                            <div className='academy-container-stats-energy-title'>{t("academyContainer.energy")}</div>
                            <div className='academy-container-stats-energy-title'>{data.userClanEnergy} / 10</div>
                        </div>

                        <div className='academy-container-stats-energy-bar justify-content-center w-100'>
                            <div className='energy_bar d-flex justify-content-center w-100'>
                                <div className='container-energy-bar d-flex align-items-center position-relative'>
                                    <div id='energy-bar' className='energy-bar w-100 d-flex justify-content-start align-items-center'>
                                        {[...Array(data.userClanEnergy)].map(() => {
                                            return (
                                                <div id='energy' className='energy-bar-fill h-100 d-flex align-items-center justify-content-start'>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='academy-container-stats-energy-button'>
                            <div onClick={restoreOnClick} className='academy-container-button d-flex justify-content-center position-relative'>
                                <svg width="110" height="34" viewBox="0 0 110 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M108.948 16.882L93.7429 32.7614H24.7623L7.30811 16.882L24.7623 1H93.7429L108.948 16.882Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                    <path d="M103.639 17.1206L88.4348 32.9999H19.4542L2 17.1206L19.4542 1.23853H88.4348L103.639 17.1206Z" stroke="#B28E4C" stroke-width="2" stroke-miterlimit="10" />
                                </svg>
                                <div className={'academy-container-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                    {!restoreIsLoading &&
                                        <>
                                            <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                                                <img src='/img/loot/energy-bottle.png' height={'auto'} width={16} style={{ marginRight: '3px' }} />
                                                <div id='restorePrice' style={{ fontSize: '14px', color: countUserLootEnergyBottle - 50 < 0 ? "rgb(177, 61, 88)" : 'white' }}>{numberFormat(data.userClanRestorePrice)}</div>
                                            </div>
                                            {t("academyContainer.restore")}
                                        </>
                                    }
                                    {restoreIsLoading &&
                                        <DataFetchLoader width={17} height={17}/>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='academy-container-stats-next-book'>

                        <div className='academy-container-stats-book d-flex justify-content-center w-100'>
                            <div className='academy-container-stats-book-image position-relative'>
                                <div style={{ width: '60px', filter: 'grayscale(100%)' }} className='d-flex justify-content-center'>
                                    <img src={`/img/academy/book_level_${data.book.level + 2}.png`} height='60px' width='auto' />
                                </div>
                            </div>
                        </div>

                        <div className='science_bar d-flex justify-content-center w-100'>
                            <div className='container-science-bar d-flex align-items-center position-relative'>
                                <div id='science-bar' className='science-bar-notactive w-100 d-flex justify-content-start align-items-center'>
                                    <div id='science' className='science-fill h-100 d-flex align-items-center justify-content-start'>
                                    </div>
                                    <div className='science-text'>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <AcademyContainerBookUpgradeModal bookLevel={data.book.level + 1} bookCurrent={data.book.current} bookPrice={data.book.price} ad={data.userClanAd} bottles={data.book.bottles}/>
                </>
            }

            {isLoading &&
                <DataFetchLoader width={86} height={86} />
            }

            <AcademyContainerBookModal />

        </div >
    )
}