//@ts-nocheck
import React, { useState } from 'react'
import './AcademyContainerBookUpgradeModal.scss'
import { useTranslation } from 'react-i18next'
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
import { useMutation, useQueryClient } from 'react-query'
import { upgradeAcademyBook } from '../../../api/clan'
import { useSelector } from 'react-redux'
import ToastError from '../../Toasts/ToastError/ToastError'
import { UseAdsgram } from '../../UseAdsgram/UseAdsgram'

export default function AcademyContainerBookUpgradeModal({ bookLevel, bookCurrent, bookPrice, ad, bottles }) {

    const { t } = useTranslation()

    const academyDamage = useSelector(state => state.userHeroesDamage.academy)
    const countUserLootEnergyBottle = useSelector((state) => state.userLootEnergyBottle.count)

    const [upgradeIsLoading, setUpgradeIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const upgradeAcademyBookMutation = useMutation((type: string) => upgradeAcademyBook(type), {
        onSuccess: (data) => {
            if(!data.ok) {
                ToastError(t(`academyContainer.errors.${data.errorCode}`), true) 
            }
            queryClient.invalidateQueries(['userLoot']).then(() => {
                queryClient.invalidateQueries(['clanAcademyInfo']).then(() => {
                    queryClient.invalidateQueries(['clanAcademyItems']).then(() => {
                        setUpgradeIsLoading(false)
                    })
                })
            })
        },
        onError: () => {
            ToastError(t("toast.toastError"), true)
        }
    })
    const upgradeAcademyBookOnClick = (type: string) => {
        if (upgradeIsLoading) return

        if(type === "bottles") {
            if (countUserLootEnergyBottle - bottles < 0) {
                const shopHeroPriceElem = document.getElementById("bottlesPrice")
                shopHeroPriceElem.classList.toggle("animate-drag");
                setTimeout(() => {
                    shopHeroPriceElem.classList.remove("animate-drag");
                }, 300);
    
                return
            }
        }

        setUpgradeIsLoading(true)

        upgradeAcademyBookMutation.mutate(type)
    }

    const showAd = UseAdsgram({
        blockId: '7787',
        onClick: () => {
            setUpgradeIsLoading(true)
        },
        onReward: () => {            
            upgradeAcademyBookMutation.mutate("ad")
        },
        onError: (error) => console.error('Error showing ad:', error),
    })

    return (
        <>
            <div className="modal fade" id={"bookUpgradeModal"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>

                <div className="modal-dialog modal-dialog-centered">

                    <div className="academy-science-modal-container modal-content w-100 flex-column">

                        <div className='academy-science-modal-desc d-flex justify-content-between'>
                            <div className='d-flex position-relative'>
                                <div style={{ width: '100px' }} className='d-flex justify-content-center'>
                                    <img src={`/img/academy/book_level_${bookLevel}.png`} height='100px' width='auto' />
                                </div>
                            </div>
                            <div className='d-flex flex-column w-100'>
                                <div className='academy-science-modal-desc-name'>{t(`academyContainerBookUpgradeModal.names.level${bookLevel}`)}</div>
                                <div className='academy-science-modal-desc-level'>{t("academyContainerBookUpgradeModal.level")} {bookLevel}</div>
                                <div>
                                    <div className='academy-science-modal-progress-bar justify-content-center w-100'>
                                        <div className='progress_bar d-flex justify-content-center w-100'>
                                            <div className='container-progress-bar position-relative w-100'>
                                                <div id='progress-bar' className='progress-bar w-100 position-relative'>
                                                    <div id='progress' className='progress-bar-fill h-100 d-flex align-items-center justify-content-start' style={{ width: `${Math.round((bookCurrent / bookPrice) * 100)}%` }}></div>
                                                    <div className='progress-text position-absolute top-50 start-50 translate-middle'>{bookCurrent} / {bookPrice}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='academy-science-modal-buttons d-flex justify-content-center align-items-center'>
                            <div className='d-flex flex-column'>
                                <div onClick={() => upgradeAcademyBookOnClick("bottles")} className='d-flex justify-content-center position-relative'>
                                    <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M59.3332 14.8968L51.04 28.7912H13.4152L3.89502 14.8968L13.4152 1H51.04L59.3332 14.8968Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                        <path d="M56.4382 15.1055L48.145 29H10.5202L1 15.1055L10.5202 1.20874H48.145L56.4382 15.1055Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                    </svg>
                                    <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                        {!upgradeIsLoading &&
                                            <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                                                <img src='/img/loot/energy-bottle.png' height={'auto'} width={16} style={{ marginRight: '3px' }} />
                                                <span id='bottlesPrice' style={{ color: countUserLootEnergyBottle - bottles < 0 ? "rgb(177, 61, 88)" : 'white' }}>{bottles}</span>
                                            </div>
                                        }
                                        {upgradeIsLoading &&
                                            <DataFetchLoader width={13} height={13} />
                                        }
                                    </div>
                                </div>
                                <div className='d-flex w-100 position-relative'>
                                    <div className='d-flex academy-science-modal-buttons-count position-absolute top-100 start-50 translate-middle'>{Number(academyDamage) * 2}</div>
                                </div>
                            </div>
                            <div className='d-flex' style={{ marginLeft: '12px', marginRight: '12px' }}>
                                <div onClick={() => upgradeAcademyBookOnClick("standard")} className='d-flex justify-content-center position-relative'>
                                    <svg width="110" height="38" viewBox="0 0 110 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M108.948 18.8673L93.7429 36.7316H24.7623L7.30811 18.8673L24.7623 1H93.7429L108.948 18.8673Z" fill="#171027" fill-opacity="0.3" stroke="#B0B0B0" stroke-miterlimit="10" />
                                        <path d="M103.639 19.1356L88.4348 36.9999H19.4542L2 19.1356L19.4542 1.26831H88.4348L103.639 19.1356Z" stroke="#B0B0B0" stroke-width="2" stroke-miterlimit="10" />
                                    </svg>
                                    <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                        {!upgradeIsLoading &&
                                            <div className='d-flex align-items-center justify-content-center m-0 p-0' style={{ fontSize: '20px' }}>
                                                {Number(academyDamage)}
                                            </div>
                                        }
                                        {upgradeIsLoading &&
                                            <DataFetchLoader width={20} height={20} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column'>
                                <div onClick={showAd} className='d-flex justify-content-center position-relative'>
                                    <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M59.3332 14.8968L51.04 28.7912H13.4152L3.89502 14.8968L13.4152 1H51.04L59.3332 14.8968Z" fill="#171027" fill-opacity="0.3" stroke="#B28E4C" stroke-miterlimit="10" />
                                        <path d="M56.4382 15.1055L48.145 29H10.5202L1 15.1055L10.5202 1.20874H48.145L56.4382 15.1055Z" stroke="#B28E4C" stroke-miterlimit="10" />
                                    </svg>
                                    <div className={'academy-science-modal-button-text position-absolute top-50 start-50 translate-middle'} style={{ lineHeight: '13px' }}>
                                        {!upgradeIsLoading &&
                                            <div className='d-flex align-items-center justify-content-center m-0 p-0'>
                                                {ad} / 10
                                            </div>
                                        }
                                        {upgradeIsLoading &&
                                            <DataFetchLoader width={13} height={13} />
                                        }
                                    </div>
                                </div>
                                <div className='d-flex w-100 position-relative'>
                                    <div className='d-flex academy-science-modal-buttons-count position-absolute top-100 start-50 translate-middle'>{Number(academyDamage) * 1.20}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}