//@ts-nocheck
import './AcademyContainerBookModal.scss'

import { useTranslation } from "react-i18next";

export default function AcademyContainerBookModal() {

    const { t } = useTranslation();

    return (
        <>
            <div className="modal fade" id={"bookModal"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="academy-info-modal-container modal-content w-100">
                        <div className='academy-info-modal-title'>
                            Элементарная наука
                        </div>
                        <div className='academy-info-modal-desc'>
                            {t("academyContainer.desc_1")}
                        </div>
                        <div className='academy-info-modal-desc'>
                            {t("academyContainer.desc_2")}
                        </div>
                        <div className='academy-info-modal-desc'>
                            {t("academyContainer.desc_3")}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}