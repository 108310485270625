//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function upsertUser(parentId: string) {
  return axios.post(
    apiURL + '/api/v1/user',
    {
      parentId: parentId,
    },
    axiosConfig,
  )
}

export async function userOnline(online: boolean) {
  return axios.post(
    apiURL + '/api/v1/user/online',
    {
      online: online,
    },
    axiosConfig
  )
}

export async function getInfo(userId: number|null) {
  const { data } = await axios.post(
    apiURL + '/api/v1/user/get-info',
    {
      userId: userId
    },
    axiosConfig
  )
  return data
}

export async function getStats() {
  const { data } = await axios.post(
    apiURL + '/api/v1/user/get-stats',
    {},
    axiosConfig
  )
  return data
}